import React, { createContext } from 'react'
import useAuthContext from './actions/authContext'
import useHomeContext from './actions/homeContext'
import useProfileContext from './actions/profileContext'

export const RootStoreContext = createContext(null)

const RootStore = ({ children }) => {
	const AuthContext = useAuthContext()
	const HomeContext = useHomeContext()
	const ProfileContext = useProfileContext()

	return (
		<RootStoreContext.Provider
			value={{
				authStore: AuthContext,
				homeStore: HomeContext,
				profileStore: ProfileContext
			}}
		>
			{children}
		</RootStoreContext.Provider>
	)
}

export default RootStore
