import React, { forwardRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { Container } from '@material-ui/core'

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})
const root = {
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-around',
	overflow: 'hidden',
	padding: 20
}
const ModalHOC = ({
	id,
	open,
	title,
	disableClose = false,
	handleClose,
	handleAgree
}) => Component => props => {
	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			fullWidth={true}
			scroll={'paper'}
			id={`${id}_head_Modal`}
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth={'md'}
			onClose={handleClose}
			aria-labelledby={id}
			aria-describedby={`${id}-slide-description`}
		>
			<DialogTitle id={id}>{title}</DialogTitle>

			<Container style={root}>
				<Component {...props} />
			</Container>
			<DialogActions>
				<Button
					onClick={handleClose}
					variant={'contained'}
					disabled={disableClose}
					color="secondary"
				>
					Отмена
				</Button>
				<Button onClick={handleAgree} variant={'contained'} color="primary">
					Сохранить
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default ModalHOC
