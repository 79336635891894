import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import {
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from '@material-ui/core'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import AutoCompleteField from '../AutoCompleteField'
import { useTranslation } from 'react-i18next'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import SearchIcon from '@material-ui/icons/Search'
import MaskedTextInput from '../MackedTextInput'
import AutoCompleteFieldWithTwoLabels from '../AutoCompleteFieldWithTwoLabels'

const ProfileFields = ({
	branches,
	fieldsValue,
	subdivisions,
	onSelect,
	onChangeText,
	isCreateUser,
	onClickSearchUser,
	setTempSubdivision,
	hasError
}) => {
	const [showPass, setShowPass] = useState(false)
	const { t } = useTranslation()
	const toDate = dateStr => {
		if (dateStr) {
			const [day, month, year] = dateStr.split('.')
			return new Date(year, month - 1, day)
		}
		return null
	}

	useEffect(() => {
		if (
			!isCreateUser &&
			subdivisions &&
			typeof fieldsValue.subdivision === 'string'
		) {
			subdivisionSelectTitle()
		}
	}, [subdivisions, fieldsValue.subdivision])

	const subdivisionSelectTitle = () => {
		const obj = subdivisions.find(item => item.name === fieldsValue.subdivision)
		setTempSubdivision(obj)
		onSelect('subdivision', obj)
	}

	const filterSubdivisionByBranch = () => {
		return subdivisions.filter(
			item => item.branchCode === fieldsValue.branch.code
		)
	}

	const subdivisionArr = () => {
		if (isCreateUser && fieldsValue.branch) {
			return filterSubdivisionByBranch()
		} else if (!isCreateUser && fieldsValue.branch) {
			return filterSubdivisionByBranch()
		} else {
			return subdivisions
		}
	}

	const handleChangeDate = date => {
		if (date.toString() !== 'Invalid Date') {
			const formatDate = format(new Date(date), 'dd.MM.yyyy')
			onSelect('birthDate', formatDate)
		}
	}

	return (
		<>
			<Grid item xs={12} md={3}>
				<TextField
					required
					disabled={!isCreateUser}
					error={hasError.username}
					name={'username'}
					autoComplete="new-password"
					value={fieldsValue.username}
					type="text"
					onChange={onChangeText}
					fullWidth
					label={t('profile.username')}
					InputProps={{
						autoComplete: 'new-password',
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="search user"
									disabled={!isCreateUser}
									onClick={onClickSearchUser}
									onMouseDown={event => event.preventDefault()}
									edge="end"
								>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</Grid>
			{isCreateUser && (
				<Grid item xs={12} md={3}>
					<TextField
						required
						error={hasError.password}
						name={'password'}
						autoComplete="off"
						type={showPass ? 'text' : 'password'}
						value={fieldsValue.password}
						InputProps={{
							autoComplete: 'new-password',
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowPass(!showPass)}
										onMouseDown={event => event.preventDefault()}
										edge="end"
									>
										{showPass ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							)
						}}
						onChange={onChangeText}
						fullWidth
						label={t('profile.password')}
					/>
				</Grid>
			)}
			<Grid item xs={12} md={3}>
				<TextField
					required
					error={hasError.firstName}
					name={'firstName'}
					value={fieldsValue.firstName}
					onChange={onChangeText}
					fullWidth
					label={t('profile.firstName')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<TextField
					required
					name={'lastName'}
					error={hasError.lastName}
					value={fieldsValue.lastName}
					onChange={onChangeText}
					fullWidth
					label={t('profile.lastName')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						margin="normal"
						name={'birthDate'}
						label={t('profile.birthday')}
						format="dd.MM.yyyy"
						value={toDate(fieldsValue.birthDate)}
						onChange={handleChangeDate}
						KeyboardButtonProps={{
							'aria-label': 'change date'
						}}
					/>
				</MuiPickersUtilsProvider>
			</Grid>
			<Grid item xs={12} md={3}>
				<MaskedTextInput
					name={'iin'}
					type={'tel'}
					mask={'999999999999999'} 
					error={hasError.iin}
					value={fieldsValue.iin}
					change={onChangeText}
					label={t('profile.pinfl')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<MaskedTextInput
					name={'inn'}
					mask={'999999999'}
					value={fieldsValue.inn}
					error={hasError.inn}
					change={onChangeText}
					label={t('profile.inn')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<MaskedTextInput
					name={'phone'}
					mask={'+\\9\\98 (99) 999-99-99'}
					value={fieldsValue.phone}
					change={onChangeText}
					type={'tel'}
					label={t('profile.phone')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<TextField
					fullWidth
					name={'email'}
					type={'email'}
					value={fieldsValue.email}
					error={hasError.email}
					onChange={onChangeText}
					label={t('profile.email')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<TextField
					fullWidth
					name={'position'}
					value={fieldsValue.position}
					onChange={onChangeText}
					label={t('profile.position')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<TextField
					fullWidth
					name={'division'}
					value={fieldsValue.division}
					onChange={onChangeText}
					label={t('profile.division')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<AutoCompleteField
					arrData={branches}
					keyArr={'name'}
					name={'branch'}
					value={fieldsValue.branch}
					setValue={onSelect}
					id="branch"
					error={hasError.branch}
					label={t('profile.branches')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<AutoCompleteFieldWithTwoLabels
					arrData={subdivisionArr()}
					keyArrOne={'name'}
					keyArrTwo={'rop'}
					value={
						typeof fieldsValue.subdivision === 'string'
							? null
							: fieldsValue.subdivision
					}
					setValue={onSelect}
					name={'subdivision'}
					id="subdivision"
					label={t('profile.subdivision')}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<FormControl fullWidth>
					<InputLabel id="male">{t('profile.male')}</InputLabel>
					<Select
						labelId="male"
						name={'male'}
						value={
							fieldsValue.male ? 'm' : fieldsValue.male === null ? null : 'g'
						}
						onChange={onChangeText}
					>
						<MenuItem value={null}>{t('profile.empty')}</MenuItem>
						<MenuItem value={'m'}>{t('profile.m')}</MenuItem>
						<MenuItem value={'g'}>{t('profile.g')}</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={3}>
				<FormControl fullWidth>
					<InputLabel id="isSystemUser">{t('profile.isSystemUser')}</InputLabel>
					<Select
						labelId="isSystemUser"
						name={'isSystemUser'}
						value={
							fieldsValue.isSystemUser ? 'y'  : 'n'
						}
						onChange={onChangeText}
					>
						<MenuItem value={'y'}>{t('profile.y')}</MenuItem>
						<MenuItem value={'n'}>{t('profile.n')}</MenuItem>
					</Select>
				</FormControl>
			</Grid>
		</>
	)
}
export default ProfileFields
