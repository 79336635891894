export const userInit = {
	username: '',
	firstName: '',
	lastName: '',
	phone: '',
	division: '',
	subdivision: null,
	branch: null,
	position: '',
	photo: '',
	birthDate: null,
	male: null,
	email: '',
	roles: [],
	password: '',
	iin: '',
	isSystemUser: null
}

export const filterInit = {
	username: '',
	userFullname: '',
	roleCode: null,
	subdivision: null,
	projectCode: null,
	branchCode: null,
	isActive: 'all',
	isSystemUser: null
}

export const newRoleInit = {
	selectedRole: null,
	selectedProject: null,
	selectedBranches: []
}
