import React from 'react'
import {
	Button,
	Chip,
	Grid,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
	rootPaper: {
		padding: theme.spacing(2)
	},
	chip: {
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(1),
		color: 'white',
		backgroundColor: '#07a79a'
	}
}))

const CardSuperset = ({ data, onEdit = () => {}, onDelete = () => {} }) => {
	const classes = useStyles()
	const { t } = useTranslation()

	return (
		<Paper elevation={3} className={classes.rootPaper}>
			<Grid
				container
				direction={'row'}
				justify={'flex-start'}
				alignItems={'center'}
				spacing={2}
			>
				<Grid item xs={12} md={12}>
					<Typography variant={'h6'}>{data?.roleTitle}</Typography>
				</Grid>
				<Grid item xs={12} md={12}>
					{data?.grantsChips?.map((value, index) => (
						<Chip
							className={classes.chip}
							label={value}
							key={`chip_reports_${index}`}
						/>
					))}
				</Grid>
				<Grid item xs={6} md={3}>
					<Button variant={'contained'} color={'secondary'} onClick={onDelete}>
						{t('profile.delete')}
					</Button>
				</Grid>
				<Grid item xs={6} md={3}>
					<Button variant={'contained'} color={'primary'} onClick={onEdit}>
						{t('profile.edit')}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	)
}
export default CardSuperset
