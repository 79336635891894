import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import './index.css'
import App from './App'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import RootStore from './store/rootStore'
import { Button, Grow } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'

const notistackRef = React.createRef()
const onClickDismiss = key => () => {
	notistackRef.current.closeSnackbar(key)
}
ReactDOM.render(
	<RootStore>
		<BrowserRouter>
			<SnackbarProvider
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				ref={notistackRef}
				action={key => (
					<Button style={{ color: 'white' }} onClick={onClickDismiss(key)}>
						Закрыть
					</Button>
				)}
				TransitionComponent={Grow}
				autoHideDuration={3000}
			>
				<Switch>
					<Route path="/" component={App} />
				</Switch>
			</SnackbarProvider>
		</BrowserRouter>
	</RootStore>,

	document.getElementById('root')
)
//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
