import React from 'react'
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AutoCompleteField from '../AutoCompleteField'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import SearchIcon from '@material-ui/icons/Search'
import AutoCompleteFieldWithTwoLabels from '../AutoCompleteFieldWithTwoLabels'

const useStyles = makeStyles(theme => ({
	rootPaper: {
		backgroundColor: '#e6f2f7',
		padding: theme.spacing(2),
		borderColor: '#b5d8e8',
		borderStyle: 'solid',
		border: 1
	},
	searchBtn: {
		color: 'white',
		backgroundColor: '#039903',
		'&:hover': {
			backgroundColor: '#03990385'
		}
	}
}))

const FilterUsers = ({
	filterValues,
	onChangeFilterText,
	onChangeFilterSelect,
	branches,
	projects,
	subdivisions,
	roles,
	onClickSearch,
	isSystemUser,
	onClickReset
}) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const md = 2
	const fullW = 12
	return (
		<Paper elevation={3} square className={classes.rootPaper}>
			<Grid
				container
				direction={'row'}
				justify={'flex-start'}
				alignItems={'center'}
				spacing={2}
			>
				<Grid item xs={fullW} md={1}>
					<TextField
						fullWidth
						name={'username'}
						value={filterValues.username}
						onChange={onChangeFilterText}
						label={t('home.login')}
					/>
				</Grid>
				<Grid item xs={fullW} md={md}>
					<TextField
						fullWidth
						name={'userFullname'}
						value={filterValues.userFullname}
						onChange={onChangeFilterText}
						label={t('home.name')}
					/>
				</Grid>
				<Grid item xs={fullW} md={md}>
					<AutoCompleteField
						arrData={branches}
						keyArr={'name'}
						name={'branchCode'}
						value={filterValues.branchCode}
						setValue={onChangeFilterSelect}
						id="branches"
						label={t('home.branches')}
					/>
				</Grid>
				<Grid item xs={fullW} md={md}>
					<AutoCompleteFieldWithTwoLabels
						arrData={subdivisions}
						keyArrOne={'name'}
						keyArrTwo={'rop'}
						name={'subdivision'}
						value={filterValues.subdivision}
						setValue={onChangeFilterSelect}
						id="subdivision"
						label={t('home.subdivision')}
					/>
				</Grid>
				<Grid item xs={fullW} md={md}>
					<AutoCompleteField
						arrData={projects}
						keyArr={'name'}
						name={'projectCode'}
						value={filterValues.projectCode}
						setValue={onChangeFilterSelect}
						id="projectCode"
						label={t('home.module')}
					/>
				</Grid>
				<Grid item xs={fullW} md={md}>
					<AutoCompleteField
						arrData={roles}
						disabled={!filterValues.projectCode}
						keyArr={'name'}
						name={'roleCode'}
						value={filterValues.roleCode}
						setValue={onChangeFilterSelect}
						id="role"
						label={t('home.role')}
					/>
				</Grid>
				<Grid item xs={fullW} md={1}>
					<FormControl fullWidth>
						<InputLabel id="isActive">{t('home.status')}</InputLabel>
						<Select
							name={'isActive'}
							value={filterValues.isActive}
							onChange={onChangeFilterText}
							labelId="isActive"
						>
							<MenuItem value="all">{t('home.all')}</MenuItem>
							<MenuItem value={true}>{t('home.active')}</MenuItem>
							<MenuItem value={false}>{t('home.block')}</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={fullW} md={3}>
					<FormControl fullWidth>
						<InputLabel id="isSystemUser">{t('profile.isSystemUser')}</InputLabel>
						<Select
							arrData={isSystemUser}
							keyArr={'isSystemUser'}
							name={'isSystemUser'}
							value={filterValues.isSystemUser ? 'y'  : filterValues.isSystemUser !== null  ? 'n' : null}
							onChange={onChangeFilterText}
							labelId="isSystemUser"
						>
							<MenuItem value={null}>{t('profile.empty')}</MenuItem>
							<MenuItem value={'y'}>{t('profile.y')}</MenuItem>
							<MenuItem value={'n'}>{t('profile.n')}</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					container
					xs={fullW}
					md={fullW}
					direction={'row'}
					justify={'flex-end'}
					alignItems={'center'}
					spacing={2}
				>
					<Grid item>
						<Button onClick={onClickReset}>
							<SettingsBackupRestoreIcon />
						</Button>
					</Grid>
					<Grid item>
						<Button
							className={classes.searchBtn}
							endIcon={<SearchIcon />}
							onClick={onClickSearch}
						>
							{t('home.search')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	)
}
export default FilterUsers
