import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../../store/rootStore'
import {
	Checkbox,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles
} from '@material-ui/core'
import { toJS } from 'mobx'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		overflow: 'auto',
		maxHeight: 600
	}
}))
const AddReports = observer(({ isBi }) => {
	const { profileStore } = useContext(RootStoreContext)
	const classes = useStyles()
	const checked = isBi
		? profileStore.user.dashboardCodes
			? toJS(profileStore.user.dashboardCodes)
			: []
		: profileStore.user.reportCodes
		? toJS(profileStore.user.reportCodes)
		: []

	const handleToggle = value => () => {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		isBi
			? profileStore.setReportCodesBi(newChecked)
			: profileStore.setReportCodes(newChecked)
	}
	const listReports = isBi
		? toJS(profileStore.dashboardReportsList)
		: toJS(profileStore.reportsList)
	return (
		<List className={classes.root}>
			{listReports.map(value => {
				const labelId = `checkbox-list-label-${value.code}`
				return (
					<ListItem
						key={value.code}
						dense
						button
						onClick={handleToggle(value.code)}
					>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={checked.indexOf(value.code) !== -1}
								tabIndex={-1}
								disableRipple
								inputProps={{ 'aria-labelledby': labelId }}
							/>
						</ListItemIcon>
						<ListItemText id={labelId} primary={value.name} />
					</ListItem>
				)
			})}
		</List>
	)
})

export default AddReports
