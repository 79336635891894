import React from 'react'
import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Chip,
	Grid,
	Typography
} from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import userImg from '../../img/user.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	content: {
		flex: '1 0 auto'
	},
	nameText: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center'
		}
	},
	subtitleText: {
		color: '#aaaaaa',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center'
		}
	},
	avatar: {
		width: 150,
		height: 150,
		borderRadius: '50%'
	},
	chip: {
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(1),
		color: 'white',
		backgroundColor: '#0664a5'
	}
}))

const CardUser = ({
	onCardClick,
	fullName,
	photo,
	subdivision,
	username,
	roles,
	block
}) => {
	const classes = useStyles()
	return (
		<Card elevation={3}>
			<CardActionArea onClick={onCardClick}>
				<CardContent className={classes.content}>
					<Grid
						container
						direction={'row'}
						justify={'flex-start'}
						alignItems={'flex-start'}
						spacing={2}
					>
						<Grid
							item
							container
							direction={'column'}
							alignItems={'center'}
							xs={12}
							md={2}
						>
							<Grid item>
								<CardMedia
									component="img"
									image={photo && photo.length > 0 ? photo : userImg}
									title="Contemplative Reptile"
									className={classes.avatar}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							container
							direction={'column'}
							xs={12}
							md={10}
							spacing={1}
						>
							<Grid item xs={12} md={12}>
								<Typography variant={'h4'} className={classes.nameText}>
									{fullName} {!block ? <BlockIcon color={'secondary'} /> : ''}
								</Typography>
							</Grid>
							<Grid item xs={12} md={12}>
								<Typography
									variant={'subtitle1'}
									className={classes.subtitleText}
								>
									{username}
								</Typography>
							</Grid>
							<Grid item xs={12} md={12}>
								<Typography
									variant={'subtitle1'}
									className={classes.subtitleText}
								>
									{subdivision}
								</Typography>
							</Grid>
							<Grid item container xs={12} md={12}>
								{roles &&
									roles.map(role => (
										<Chip
											className={classes.chip}
											key={role.id}
											label={role.project.name + ': ' + role.name}
										/>
									))}
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
		</Card>
	)
}
export default CardUser
