import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { RootStoreContext } from '../../../store/rootStore'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { toJS } from 'mobx'

const EditPass = observer(() => {
	const { profileStore } = useContext(RootStoreContext)
	const { t } = useTranslation()
	const [showPass, setShowPass] = useState(false)
	const md = 6
	const fullW = 12

	const handleChangePass = e => {
		profileStore.setNewPassword(e.target.value)
	}

	return (
		<Grid
			container
			direction={'row'}
			justify={'center'}
			alignItems={'center'}
			spacing={2}
		>
			<Grid item xs={fullW} md={md}>
				<TextField
					required
					name={'password'}
					type={showPass ? 'text' : 'password'}
					value={toJS(profileStore.newPassword)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPass(!showPass)}
									onMouseDown={event => event.preventDefault()}
									edge="end"
								>
									{showPass ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						)
					}}
					onChange={handleChangePass}
					fullWidth
					label={t('profile.password')}
				/>
			</Grid>
		</Grid>
	)
})
export default EditPass
