import { useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { newRoleInit, userInit } from '../../constants/initialStates'
import axios from 'axios'
import { AUTH_API_URL, CAR_API_URL, SUB_API_URL } from '../../lib/env'
import { parseJson } from '../../lib/utils'

const initialValues = {
	loading: false,
	user: userInit,
	newRole: newRoleInit,
	dashboardReportsList: [],

	reportsList: [],
	userList: [],
	superset: {
		accessList: [],
		rolesList: []
	},
	userIdToCopy: null,
	newPassword: '',
	tempSubdivision: {}
}

const ProfileContext = () => {
	const store = useLocalObservable(() => ({
		/*observables*/
		...initialValues,
		/*asynchronous actions*/
		setLoading() {
			runInAction(() => {
				store.loading = !store.loading
			})
		},
		setUserIdToCopy(value) {
			runInAction(() => {
				store.userIdToCopy = value
			})
		},
		clearUserIdToCopy() {
			runInAction(() => {
				store.userIdToCopy = null
			})
		},
		setUserValue(name, value) {
			runInAction(() => {
				store.user = { ...store.user, [name]: value }
			})
		},
		clearUserValue() {
			runInAction(() => {
				store.user = userInit
			})
		},
		setNewPassword(value) {
			runInAction(() => {
				store.newPassword = value
			})
		},
		clearNewPassword() {
			runInAction(() => {
				store.newPassword = ''
			})
		},
		clearState() {
			runInAction(() => {
				store.user = userInit
				store.newRole = newRoleInit
				store.loading = false
				store.dashboardReportsList = []
				store.reportsList = []
				store.userList = []
				store.userIdToCopy = ''
				store.newPassword = ''
				store.tempSubdivision = {}
			})
		},
		deleteUserRole(roleIndex) {
			let userRoles = store.user.roles
			userRoles.splice(roleIndex, 1)
			runInAction(() => {
				store.user = { ...store.user, roles: userRoles }
			})
		},
		setDataNewRoleToEdit(roleIndex, branches) {
			try {
				const userRoles = store.user.roles[roleIndex]
				const branchArr =
					userRoles &&
					branches &&
					branches.length > 0 &&
					branches.filter(value => userRoles.branchList.includes(value.code))
				const selectedProject = userRoles && {
					code: userRoles.project.code,
					name: userRoles.project.name
				}
				const selectedRole = userRoles && {
					code: userRoles.code,
					name: userRoles.name
				}
				runInAction(() => {
					store.newRole = {
						selectedRole: selectedRole,
						selectedProject: selectedProject,
						selectedBranches: branchArr
					}
				})
			} catch (e) {
				console.log(e)
			}
		},
		setNewRoleValue(name, value) {
			runInAction(() => {
				store.newRole = { ...store.newRole, [name]: value }
			})
		},
		clearNewRoleValue() {
			runInAction(() => {
				store.newRole = newRoleInit
			})
		},
		addToUserNewRole() {
			let newRole = store.user.roles
			newRole.push({
				code: store.newRole.selectedRole.code,
				name: store.newRole.selectedRole.name,
				project: {
					code: store.newRole.selectedProject.code,
					name: store.newRole.selectedProject.name
				},
				branchList: store.newRole.selectedBranches.map(value => value.code)
			})
			runInAction(() => {
				store.user.roles = newRole
			})
			this.clearNewRoleValue()
		},
		setReportCodesBi(value) {
			runInAction(() => {
				store.user.dashboardCodes = value
			})
		},
		setReportCodes(value) {
			runInAction(() => {
				store.user.reportCodes = value
			})
		},
		setTempSubdivision(value) {
			runInAction(() => {
				store.tempSubdivision = value
			})
		},
		isNewSubdivisionForSales() {
			return (
				store.tempSubdivision.hasOwnProperty('name') &&
				store.user.subdivision.hasOwnProperty('name') &&
				store.user.subdivision.hasOwnProperty('projectId') &&
				store.user.subdivision.hasOwnProperty('projectId') &&
				store.user.subdivision.name !== store.tempSubdivision.name &&
				store.user.subdivision.projectId === 18 &&
				store.tempSubdivision.projectId === 18
			)
		},
		async getUser(id) {
			this.setLoading()
			try {
				const res = await axios.get(AUTH_API_URL + '/users/getUsersById', {
					params: { userId: id }
				})
				await runInAction(() => {
					store.user = res.data[0]
				})
				this.setLoading()
				return
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async getUsersByPinfl() {
			this.setLoading()
			try {
				const res = await axios.get(
					AUTH_API_URL + '/users/getUsersByIIN/' + store.user?.iin
				)

				this.setLoading()
				return res.data
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async uploadPhoto(file) {
			this.setLoading()
			try {
				const res = await axios.post(`${CAR_API_URL}/files/uploadFile`, file)
				await runInAction(() => {
					store.user.photo = res.data.fileDownloadUri
				})
				this.setLoading()
				return
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},

		async createUser() {
			this.setLoading()
			const data = {
				...store.user,
				subdivision: store.user.subdivision
					? store.user.subdivision.name
					: null,
				branchCode: store.user.branch.code
			}
			try {
				const res = await axios.post(AUTH_API_URL + '/users', data)
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					if (error?.response?.data?.message === 'Username already exists') {
						return { errStatus: error?.response?.status, msg: 'Логин уже занят' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async updateUser() {
			const data = {
				...store.user,
				subdivision: store.user.subdivision
					? store.user.subdivision.name
					: null,
				branchCode: store.user.branch ? store.user.branch.code : null
			}
			this.setLoading()
			try {
				const res = await axios.put(AUTH_API_URL + '/users', data)
				this.setLoading()
				if (this.isNewSubdivisionForSales()) {
					await this.updateSalesSub()
					await this.updateEventSub()
				}
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async getDashboardReports() {
			this.setLoading()
			try {
				const res = await axios.get(AUTH_API_URL + '/dictionary/dashboards')
				await runInAction(() => {
					store.dashboardReportsList = res.data
				})
				this.setLoading()
				return
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async getReports() {
			this.setLoading()
			try {
				const res = await axios.get(AUTH_API_URL + '/dictionary/reportCodes')
				await runInAction(() => {
					store.reportsList = res.data
				})
				this.setLoading()
				return
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async updateReports() {
			this.setLoading()
			const data = {
				username: store.user.username,
				reportCodes:
					store.user.reportCodes.length == 0 ? null : store.user.reportCodes
			}
			try {
				const res = await axios.put(
					AUTH_API_URL + '/users/updateReportCodes',
					data
				)
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async updateDashboardReports() {
			this.setLoading()
			const data = {
				username: store.user.username,
				dashboardCodes:
					store.user.dashboardCodes.length == 0
						? null
						: store.user.dashboardCodes
			}
			try {
				const res = await axios.put(
					AUTH_API_URL + '/users/updateDashboardCodes',
					data
				)
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async switchStatusUser() {
			this.setLoading()
			try {
				const res = await axios.post(AUTH_API_URL + '/users/blockUser', {
					id: store.user.id,
					isActive: !store.user.isActive
				})
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async getAllUsers(username = '') {
			this.setLoading()
			try {
				const res = await axios.post(AUTH_API_URL + '/users/getUsers', {
					username: username ? username : undefined,
					limit: 2000
				})
				if (!username) {
					await runInAction(() => {
						store.userList = res.data.userList
					})
				}
				this.setLoading()
				return res.data.countFiltered
			} catch (error) {
				this.setLoading()
				if (error?.response?.status === 401) {
					return { errStatus: 401, msg: 'Ошибка автаризации' }
				}
				return {
					errStatus: error?.response?.status,
					msg: error?.response?.data?.message
				}
			}
		},
		async getRolesUser() {
			this.setLoading()
			try {
				const res = await axios.get(
					AUTH_API_URL + '/roles/user/' + store.userIdToCopy.id
				)
				if (store.user.roles.length > 0) {
					let projects = []
					res.data.map(project => {
						let roleExists = false
						store.user.roles.map(role => {
							if (role.project.code === project.project.code) {
								roleExists = true
							}
							return null
						})

						if (!roleExists) {
							projects.push(project)
						}
						return null
					})
					await runInAction(() => {
						store.user.roles = projects
					})
				} else {
					await runInAction(() => {
						store.user.roles = res.data
					})
				}
				this.setLoading()
				return
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async deleteUserRoleServ(index) {
			this.setLoading()
			const roleId = store.user.roles[index].id
			try {
				const res = await axios.post(
					AUTH_API_URL + '/users/removeRoleFromUser',
					{
						userId: store.user.id,
						roleId: roleId
					}
				)
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async addUserRoleServ() {
			this.setLoading()
			try {
				const res = await axios.post(AUTH_API_URL + '/users/addRoleToUser', {
					userId: store.user.id,
					roleId: store.newRole.selectedRole.id,
					branchList: store.newRole.selectedBranches.map(value => value.code)
				})
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async editPass() {
			this.setLoading()
			try {
				const res = await axios.get(AUTH_API_URL + '/users/changePassword', {
					params: {
						username: store.user.username,
						newPassword: store.newPassword
					}
				})
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async updateSalesSub() {
			this.setLoading()
			try {
				const res = await axios.post(
					SUB_API_URL + '/api-sale/sales/subdivision',
					{
						assignTo: store.user.username,
						subdivision: store.user.subdivision.name
					}
				)
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},

		async updateEventSub() {
			this.setLoading()
			try {
				const res = await axios.post(
					SUB_API_URL + '/api-notification/events/subdivision',
					{
						assignTo: store.user.username,
						subdivision: store.user.subdivision.name
					}
				)
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async getSupersetAccesses() {
			this.setLoading()
			try {
				const res = await axios.get(
					SUB_API_URL + '/api-dashboard/roles/getAccesses/' + store.user.id
				)
				this.setLoading()
				runInAction(() => {
					store.superset.accessList = parseJson(res?.data?.grantValue, [])
				})
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async getSupersetRoles() {
			this.setLoading()
			try {
				const res = await axios.get(SUB_API_URL + '/api-dashboard/roles')
				this.setLoading()
				runInAction(() => {
					store.superset.rolesList = res?.data || []
				})
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async getSupersetRoleInfo(id) {
			this.setLoading()
			try {
				const res = await axios.get(
					SUB_API_URL + '/api-dashboard/roles/getInfo/' + id
				)
				this.setLoading()
				return parseJson(res?.data?.grantValue, [])
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		},
		async saveSupersetRole(data) {
			this.setLoading()
			const dataToSend = {
				userId: store.user.id,
				jsonData: JSON.stringify(data)
			}
			try {
				const res = await axios.patch(
					SUB_API_URL + '/api-dashboard/roles',
					dataToSend
				)
				this.setLoading()
				return res
			} catch (error) {
				this.setLoading()
				if (error.hasOwnProperty('response')) {
					if (error?.response?.status === 401) {
						return { errStatus: 401, msg: 'Ошибка автаризации' }
					}
					return {
						errStatus: error?.response?.status,
						msg: error?.response?.data?.message
					}
				}
			}
		}
	}))

	return store
}

export default ProfileContext
