import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

const AutoCompleteFieldWithTwoLabels = ({
	arrData,
	keyArrOne = 'id',
	keyArrTwo = 'id',
	label,
	value,
	name,
	setValue,
	disabled,
	id = 'autocomplete'
}) => {
	return (
		<Autocomplete
			id={id}
			options={arrData ? arrData : []}
			disabled={disabled}
			getOptionLabel={option =>
				option && option[keyArrOne] + ' ' + option[keyArrTwo]
			}
			getOptionSelected={(option, value) => option.id === value.id}
			value={value}
			onChange={(event, newValue) => {
				setValue(name, newValue)
			}}
			renderInput={params => <TextField {...params} fullWidth label={label} />}
		/>
	)
}
export default AutoCompleteFieldWithTwoLabels
