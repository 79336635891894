import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const AutoCompleteFieldCheckbox = ({
	arrData,
	keyArr = 'name',
	label,
	value,
	name,
	setValue,
	disabled,
	id = 'autocompleteCheckbox'
}) => {
	return (
		<Autocomplete
			id={id}
			multiple
			limitTags={1}
			options={arrData ? arrData : []}
			disabled={disabled}
			disableCloseOnSelect
			getOptionLabel={option => option[keyArr]}
			getOptionSelected={(option, value) =>
				option && value && option[keyArr] === value[keyArr]
			}
			value={value}
			onChange={(event, newValue) => {
				setValue(name, newValue)
			}}
			renderOption={(option, { selected }) => (
				<React.Fragment>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 2 }}
						checked={selected}
					/>
					{option[keyArr]}
				</React.Fragment>
			)}
			style={{ minWidth: 300 }}
			renderInput={params => <TextField {...params} fullWidth label={label} />}
		/>
	)
}
export default AutoCompleteFieldCheckbox
