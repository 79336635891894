import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DoneIcon from '@material-ui/icons/Done';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import axios from 'axios';
import { RootStoreContext } from '../../store/rootStore';

const useStyles = makeStyles(theme => ({
  root:{
    zIndex: '15000 !important',
    overflowX: 'hidden',
    overflowY: 'hidden',
    '& .MuiBackdrop-root': {
      backdropFilter: 'blur(16px)',
      transition: 'none !important',
      backgroundColor: 'rgba(0, 0, 0, 0.6) !important'
    }
  },
  paper: {
    borderRadius: 16,
  },
  button: {
    borderRadius: 8,
    border: '1px solid ' + '#0B6EC7',
    margin: '4px 8px',
    fontWeight: 600
  },
  copy: {
    borderRadius: 8,
    margin: '4px 8px',
    textTransform: 'none',
    fontWeight: 600,
  },
  back: {
    borderRadius: 8,
    margin: '4px 8px',
    textTransform: 'none',
    fontWeight: 600,
    color: '#FF4848',
    backgroundColor: '#FFE9E9',
    '&:hover': {
      backgroundColor: '#FF4848',
      color: '#FFF',
    }
  },
  ticket: {
    borderRadius: 8,
    margin: '4px 8px',
    textTransform: 'none',
    fontWeight: 600,
    color: '#0B6EC7',
    backgroundColor: '#E9F2FF',
    '&:hover': {
      backgroundColor: '#0B6EC7',
      color: '#FFF',
    }
  },
  paste: {
    borderRadius: 8,
    color: '#4BAB43',
    backgroundColor: '#EDFFF6',
    margin: '4px 8px',
    textTransform: 'none',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#EDFFF6'
    },
  },
  iconE: {
    color: '#FF4848',
    position: 'absolute',
    height: 32,
    width: 32,
    right: 8,
    top: 8
  },
  iconS: {
    color: '#F7D117',
    position: 'absolute',
    height: 32,
    width: 32,
    right: 8,
    top: 8
  },
  iconSup: {
    color: '#0B6EC7',
    position: 'absolute',
    height: 32,
    width: 32,
    right: 8,
    top: 8
  },
  sub: {
    color: '#aaa',
    fontWeight: 600,
    marginLeft: 4,
    marginBottom: 4
  },
  textArea: {
    backgroundColor: '#FFE9E9',
    borderRadius: 8,
    padding: '8px 24px !important',
    border: '1px solid #aaa'
  },
  textField: {
    border: '1px solid #DCE6F3',
    borderRadius: 10,
    backgroundColor: '#FFFFFF',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    height: 72,
    fontWeight: 600,
    fontSize: 12,
    paddingLeft: 16,
    '&$disabled': {
      backgroundColor: '#e0e0e0',
      '&:hover': {
        backgroundColor: '#e0e0e0'
      }
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#94A1B4'
    },
    '&:inputMultiline': {
      height: 80,
    },
    '&$focused': {
      backgroundColor: '#FFFFFF',
      borderColor: '#94A1B4'
    },
    '&$error': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #FF4848',
    }
  },
  error: {
    border: '1px solid #FF4848',
    borderRadius: 10,
    backgroundColor: '#FFFFFF',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    height: 72,
    fontWeight: 600,
    fontSize: 12,
    paddingLeft: 16
  },
  inputLabel: {
    color: '#94A1B4',
    fontWeight: 600,
    fontSize: 12,
    paddingLeft: 6,
    '&.MuiInputLabel-shrink': {
      color: '#94A1B4',
      fontWeight: 600,
      fontSize: 10
    },
    '&.focused': {
      color: '#94A1B4',
      fontWeight: 600,
      fontSize: 10
    }
  }
}))


const isDevelop =
	window.location.hostname.includes('test') ||
	window.location.hostname.includes('192.168') ||
	window.location.hostname.includes('localhost')

const MISC_API_URL = isDevelop
	? 'https://test-uz.asterx.kz/api-misc'
	: 'https://asterx.uz/api-misc'

const ErrorModal = ({e, close, snackbar}) => {
  const { authStore } = useContext(RootStoreContext)
  const classes = useStyles()
  const [text, setText] = useState('')
  const [isCopy, setIsCopy] = useState(false)
  const [support, setSupport] = useState(false)
  const [supportData, setSupportData] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleOpenSupport = () => {
    setSupportData({
      department: 'AsterX',
      module: 'ППО',
      status: 'Новый',
      createdDate: moment().format(),
      createdBy: authStore?.user?.data?.sub,
      createdByFullname: authStore?.user?.data?.lastName + ' ' + authStore?.user?.data?.firstName,
      type: 'Ошибка',
      branchCode: authStore?.user?.data?.branchCode,
      userUsername: authStore?.user?.data?.sub,
      email: authStore?.user?.data?.email
    })
    setError(false)
    setSupport(!support)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
    setIsCopy(true)
  }
  const handleClose = () => {
    setIsCopy(false)
    close()
  }

  const handleSupportSend = () => {
    setLoading(true)
    if (!supportData?.description || supportData?.description?.length < 10) {
      setLoading(false)
      return setError(true);
    }
    const data = {
      ...supportData,
      description: !supportData?.description?.includes(text)
          ? supportData?.description + '\n' + text
          : supportData?.description
    }
    try {
      axios.post(MISC_API_URL + '/support', data).then(res => {
        if (!!res?.data) {
          handleOpenSupport()
          handleClose()
        }
        setLoading(true)
      })
    } catch (e) {
      console.log(e)
      setLoading(true)
    }
  }

  const handleMessageChange = (e) => {
    (async () => {
      if(e?.target !== null && e?.target?.value !== null) {
        await setSupportData(prevState => ({...prevState, description: e.target.value}))
        setError(false)
      }
    })();
  }

  useEffect(() => {
    if (!snackbar) {
      let builder = ''

      if(!!localStorage.getItem('token')) {
        builder += 'Username:\n' + jwtDecode(localStorage.getItem('token'))?.sub + '\n\n'
      }
      if(!!e?.config?.url) {
        builder += 'URL:\n' + e?.config?.url + '\n\n'
      }
      if(!!e?.request) {
        builder += 'Request:\n' +
            (!!e?.request?.readyState ? `readyState: ${e?.request?.readyState}\n` : '') +
            (!!e?.request?.responseType ? `responseType: ${e?.request?.responseType}\n` : '') +
            (!!e?.request?.status || e?.request?.status === 0 ? `status: ${e?.request?.status === 0 ? 'Not Available' : e?.request?.status}` : '') + '\n\n'
      }
      if(!!e?.response?.data) {
        builder += 'Response:\n' + (typeof(e?.response?.data) === 'string' ? e?.response?.data :
            Object.keys(e?.response?.data)?.map(i =>
                `${i}: ${e?.response?.data?.[i]}`
            )?.join('\n')) + '\n\n'
      }
      if(!!e?.response?.headers) {
        builder += 'Headers:\n' + Object.keys(e?.response?.headers)?.map(i =>
            `${i}: ${e?.headers?.[i]}`
        )?.join('\n') + '\n\n'
      }
      if(!!e?.config?.data) {
        builder += 'RequestDataJSON:\n' + e?.config?.data + '\n\n'
      }
      if(!!localStorage) {
        builder += 'LocalStorage:\n' + Object.keys(localStorage)?.map(i =>
            `${i}: ${localStorage?.[i]}`
        )?.join('\n')
      }
      setText(builder)
    }
  }, [e])

  return (
      <>
        <Dialog
            open={e}
            classes={{paper: classes.paper, root: classes.root}}
        >
          <ErrorIcon classes={{root: !!snackbar ? classes.iconS : classes.iconE}} />
          <DialogTitle>
            <Typography variant={'h5'} style={{fontWeight: 600}}>
              {!!snackbar ? 'Внимание' : 'Ошибка'}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ paddingLeft: 24, paddingRight: 24 }}>
            {!snackbar ?
                <>
                  <Typography classes={{root: classes.sub}}>
                    Отправьте данные из этого окна разработчикам:
                  </Typography>
                  <Grid classes={{root: classes.textArea}}>
                    {!!localStorage.getItem('token') &&
                        <>
                          <Typography style={{color: '#FF4848', fontWeight: 600, marginLeft: -8}}>
                            Username:
                          </Typography>
                          {jwtDecode(localStorage.getItem('token'))?.sub}
                        </>
                    }
                    {!!e?.config?.url &&
                        <>
                          <Typography style={{color: '#FF4848', fontWeight: 600, marginLeft: -8}}>
                            URL:
                          </Typography>
                          <Typography style={{wordWrap: 'break-word', fontSize: 15, fontWeight: 500}}>
                            {e?.config?.url}
                          </Typography>
                        </>
                    }
                    {!!e?.request &&
                        <>
                          <Typography style={{color: '#FF4848', fontWeight: 600, marginLeft: -8}}>
                            Request:
                          </Typography>
                          {!!e?.request?.readyState &&
                              <Grid container direction={'row'}>
                                <Typography style={{fontWeight: 600, marginLeft: -4, marginRight: 4}}>
                                  readyState:
                                </Typography>
                                <Typography style={{fontSize: 15, fontWeight: 500}}>
                                  {e?.request?.readyState}
                                </Typography>
                              </Grid>
                          }
                          {!!e?.request?.responseType &&
                              <Grid container direction={'row'}>
                                <Typography style={{fontWeight: 600, marginLeft: -4, marginRight: 4}}>
                                  responseType:
                                </Typography>
                                <Typography style={{fontSize: 15, fontWeight: 500}}>
                                  {e?.request?.responseType}
                                </Typography>
                              </Grid>
                          }
                          {!!e?.request?.status || e?.request?.status === 0 &&
                              <Grid container direction={'row'}>
                                <Typography style={{fontWeight: 600, marginLeft: -4, marginRight: 4}}>
                                  status:
                                </Typography>
                                <Typography style={{fontSize: 15, fontWeight: 500}}>
                                  {e?.request?.status === 0 || e?.request?.status === 0 ? 'Not Available' : e?.request?.status}
                                </Typography>
                              </Grid>
                          }
                        </>
                    }
                    {!!e?.response?.data &&
                        <>
                          <Typography style={{color: '#FF4848', fontWeight: 600, marginLeft: -8}}>
                            Response:
                          </Typography>
                          {typeof(e?.response?.data) === 'string' ?
                              <Typography style={{fontSize: 15, fontWeight: 500}}>
                                {e?.response?.data}
                              </Typography>
                              : Object.keys(e?.response?.data)?.map(i =>
                                  <Grid container direction={'row'}>
                                    <Typography style={{fontWeight: 600, marginLeft: -4, marginRight: 4}}>
                                      {i}:
                                    </Typography>
                                    <Typography style={{fontSize: 15, fontWeight: 500}}>
                                      {e?.response?.data?.[i]}
                                    </Typography>
                                  </Grid>
                              )}
                        </>
                    }
                    {!!e?.response?.headers &&
                        <>
                          <Typography style={{color: '#FF4848', fontWeight: 600, marginLeft: -8}}>
                            Headers:
                          </Typography>
                          {Object.keys(e?.response?.headers)?.map(i =>
                              <Grid container direction={'row'}>
                                <Typography style={{fontWeight: 600, marginLeft: -4, marginRight: 4}}>
                                  {i}:
                                </Typography>
                                <Typography style={{fontSize: 15, fontWeight: 500}}>
                                  {e?.response?.headers?.[i]}
                                </Typography>
                              </Grid>
                          )}
                        </>
                    }
                    {!!e?.config?.data &&
                        <>
                          <Typography style={{color: '#FF4848', fontWeight: 600, marginLeft: -8}}>
                            RequestDataJSON:
                          </Typography>
                          <Typography style={{wordWrap: 'break-word', fontSize: 15, fontWeight: 500}}>
                            {JSON.stringify(e?.config?.data)}
                          </Typography>
                        </>
                    }
                    {!!localStorage &&
                        <>
                          <Typography style={{color: '#FF4848', fontWeight: 600, marginLeft: -8}}>
                            LocalStorage:
                          </Typography>
                          {Object.keys(localStorage)?.map(i =>
                              <>
                                <Typography style={{fontWeight: 600, marginLeft: -4}}>
                                  {i}:
                                </Typography>
                                <Typography style={{wordWrap: 'break-word', fontSize: 15, fontWeight: 500}}>
                                  {i !== 'token' ? localStorage?.[i] : 'Есть'}
                                </Typography>
                              </>
                          )}
                        </>
                    }
                  </Grid>
                </>
                :
                <Typography style={{fontWeight: 600, fontSize: 20, padding: '0px 8px'}}>
                  {e}
                </Typography>
            }
          </DialogContent>
          <DialogActions>
            <Grid container direction={'row'} justify={'space-between'} alignItems={'space-between'}>
              <Grid item>
                {!snackbar &&
                    <Button onClick={handleCopy} classes={{root: isCopy ? classes.paste : classes.copy}}>
                      <>
                        {!!isCopy ?
                            <>
                              <DoneIcon style={{marginRight: 4}}/>
                              Скопировано
                            </>
                            :
                            <>
                              <FileCopyOutlinedIcon style={{marginRight: 4}}/>
                              Копировать
                            </>
                        }
                      </>
                    </Button>
                }
              </Grid>
              {!snackbar &&
                  <Grid item>
                    <Button onClick={handleOpenSupport} classes={{root: classes.ticket}}>
                      <Typography style={{fontWeight: 600}}>
                        Отправить заявку
                      </Typography>
                    </Button>
                  </Grid>
              }
              <Grid item>
                <Button color={'primary'} onClick={handleClose} classes={{root: classes.button}}>
                  <Typography style={{fontWeight: 600}}>
                    ОК
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={support}
            classes={{paper: classes.paper, root: classes.root}}
        >
          <FavoriteIcon classes={{root: classes.iconSup}} />
          <DialogTitle>
            <Typography variant={'h5'} style={{fontWeight: 600}}>
              Заявка в поддержку
            </Typography>
          </DialogTitle>
          <DialogContent style={{ paddingLeft: 24, paddingRight: 24 }}>
            <TextField
                error={!!error}
                fullWidth
                multiline
                rows={3}
                label={'Комментарий (Не менее 10 сивлов)'}
                value={supportData?.description || undefined}
                onChange={(e) => { handleMessageChange(e) }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: 'focused'
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.textField,
                    error: classes.error,
                    focused: classes.focused,
                    disabled: classes.disabled
                  },
                  disableUnderline: true,
                }}
                variant="filled"
            />
          </DialogContent>
          <DialogActions>
            <Grid container direction={'row'} justify={'space-between'} alignItems={'space-between'}>
              <Grid item>
                <Button onClick={handleOpenSupport} classes={{root: classes.back}}>
                  <Typography style={{fontWeight: 600}}>
                    Отмена
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleSupportSend} classes={{root: classes.ticket}} disabled={!!loading}>
                  <Typography style={{fontWeight: 600}}>
                    Отправить
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
  )
}

export default ErrorModal
