import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			auth ? <Component {...props} /> : <Redirect to="/signin" />
		}
	/>
);
export default PrivateRoute;
