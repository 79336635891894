import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

const AutoCompleteField = ({
	arrData,
	keyArr = 'name',
	label,
	value,
	name,
	setValue,
	error,
	disabled,
	id = 'autocomplete'
}) => {
	return (
		<Autocomplete
			id={id}
			options={arrData ? arrData : []}
			disabled={disabled}
			getOptionLabel={option => option && option[keyArr]}
			getOptionSelected={(option, value) =>
				option && value && option[keyArr] === value[keyArr]
			}
			value={value}
			onChange={(event, newValue) => {
				setValue(name, newValue)
			}}
			renderInput={params => <TextField  {...params} error={!!error} fullWidth label={label} />}
		/>
	)
}
export default AutoCompleteField
