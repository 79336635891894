const isDevelop =
	window.location.hostname.includes('test') ||
	window.location.hostname.includes('localhost')

export const AUTH_API_URL = isDevelop
	? 'https://test-uz.asterx.kz/api-auth'
	: 'https://asterx.uz/api-auth'

export const CAR_API_URL = isDevelop
	? 'https://test-uz.asterx.kz/api-car'
	: 'https://asterx.uz/api-car'

export const SUB_API_URL = isDevelop
	? 'https://test-uz.asterx.kz'
	: 'https://asterx.uz'
