import { useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { AUTH_API_URL } from '../../lib/env'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { setAuthToken } from '../../lib/utils'

const initialValues = {
	user: {
		token: '',
		data: {}
	},
	loading: false
}

const AuthContext = () => {
	const store = useLocalObservable(() => ({
		/*observables*/
		...initialValues,
		/*asynchronous actions*/
		setLoading() {
			runInAction(() => {
				store.loading = !store.loading
			})
		},
		clearStorage() {
			runInAction(() => {
				store.user.token = ''
				store.user.data = {}
			})
		},
		setTokenFromQuery(token) {
			const decoded = jwt_decode(token)
			setAuthToken(token)
			runInAction(() => {
				store.user.token = token
				store.user.data = decoded
			})
		},
		async login(userData) {
			try {
				const res = await axios.post(AUTH_API_URL + '/authenticate', userData)
				const { token } = res.data
				const decoded = jwt_decode(token)
				if (token) {
					setAuthToken(token)
					await runInAction(() => {
						store.user.token = token
						store.user.data = decoded
					})
					return decoded
				}
			} catch (error) {
				if (error.response.status === 401) {
					return { errStatus: 401, msg: 'Неправильный логин или пароль' }
				}
			}
		}
	}))

	return store
}

export default AuthContext
