import React, { useContext, useEffect, useRef } from 'react'
import {
	Button,
	CircularProgress,
	Container,
	Grid,
	makeStyles,
	TablePagination,
	Typography
} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../store/rootStore'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import FilterUsers from '../components/Home/FilterUsers'
import CardUser from '../components/Home/CardUser'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		padding: 10
	},
	buttonCreate: {
		backgroundColor: '#0089d0',
		color: 'white',
		height: 50,
		'&:hover': {
			backgroundColor: 'black'
		}
	}
}))

const Home = observer(() => {
	const { homeStore } = useContext(RootStoreContext)
	const { t } = useTranslation()
	const history = useHistory()
	const { enqueueSnackbar } = useSnackbar()
	const _isMounted = useRef(true)
	const classes = useStyles()

	useEffect(() => {
		if (homeStore.users.length === 0) {
			getFilterValues()
		}
		return () => {
			_isMounted.current = false
		}
	}, [])

	useEffect(() => {
		getUsers()
	}, [homeStore.page, homeStore.rowsPerPage])

	useEffect(() => {
		if (homeStore.filter.projectCode) {
			homeStore.getRoles(homeStore.filter.projectCode.code).then(res => {
				setErrOnRes(res)
			})
		}
	}, [homeStore.filter.projectCode])

	const setErrOnRes = res => {
		if (res && res.hasOwnProperty('errStatus')) {
			if (res.errStatus === 401) {
				history.push('/signin')
				return
			} else {
				enqueueSnackbar(res.msg, { variant: 'error' })
				return
			}
		}
		return
	}
	const getUsers = () => {
		homeStore.getUsers().then(res => {
			setErrOnRes(res)
		})
	}
	const getFilterValues = () => {
		homeStore.getBranches().then(res => {
			setErrOnRes(res)
		})
		homeStore.getProjects().then(res => {
			setErrOnRes(res)
		})
		homeStore.getSubdivisions().then(res => {
			setErrOnRes(res)
		})
	}
	const handleChangePage = (event, newPage) => {
		homeStore.setPages(newPage, homeStore.rowsPerPage)
	}

	const handleChangeRowsPerPage = event => {
		homeStore.setPages(0, parseInt(event.target.value, 10))
	}

	const handleChangeFilterText = e => {
		homeStore.setFilter(e.target.name, e.target.value)
		if (e.target.name === 'isSystemUser') {
			homeStore.setFilter(e.target.name, e.target.value === 'y'? true  : e.target.value === 'n'  ? false : null)
		}
	}
	const handleChangeFilterSelect = (name, value) => {
		homeStore.setFilter(name, value)
	}
	const setInitialPages = () => {
		homeStore.setPages(0, homeStore.rowsPerPage)
	}
	const updateListUsers = () => {
		if (homeStore.page === 0) {
			getUsers()
			return
		}
		setInitialPages()
	}
	const resetFilter = () => {
		homeStore.clearFilter()
		updateListUsers()
	}

	return (
		<>
			{homeStore.loading && (
				<CircularProgress
					style={{
						position: 'absolute',
						zIndex: 9,
						top: '50%',
						left: '48%'
					}}
					color="secondary"
				/>
			)}

			<Container className={classes.root} maxWidth={'lg'}>
				<Grid
					container
					direction={'row'}
					justify={'flex-start'}
					alignItems={'center'}
					spacing={2}
				>
					<Grid item xs={12} md={12}>
						<Typography variant={'h4'} style={{ fontWeight: 600 }}>
							{t('home.users')}
						</Typography>
					</Grid>
					<Grid item xs={12} md={12}>
						<Button
							variant={'contained'}
							className={classes.buttonCreate}
							onClick={() => history.push('/create-user')}
						>
							{t('home.createUser')}
						</Button>
					</Grid>
					<Grid item xs={12} md={12}>
						<FilterUsers
							filterValues={homeStore.filter}
							onChangeFilterText={handleChangeFilterText}
							onChangeFilterSelect={handleChangeFilterSelect}
							branches={homeStore.branches}
							projects={homeStore.projects}
							subdivisions={homeStore.subdivisions}
							roles={homeStore.roles}
							isSystemUser={homeStore.isSystemUser}
							onClickSearch={updateListUsers}
							onClickReset={resetFilter}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<TablePagination
							component="div"
							count={homeStore.pageCount}
							page={homeStore.page}
							onChangePage={handleChangePage}
							rowsPerPage={homeStore.rowsPerPage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							labelRowsPerPage={''}
						/>
					</Grid>
					{homeStore.users.length !== 0 &&
						homeStore.users.map((user, index) => (
							<Grid item xs={12} md={12} key={`grid_user_${user.id}_${index}`}>
								<CardUser
									fullName={`${user.firstName} ${user.lastName}`}
									photo={user.photo}
									username={user.username}
									block={user.isActive}
									key={`user_${user.id}_${index}`}
									subdivision={user.subdivision}
									roles={user.roles}
									onCardClick={() => history.push(`/edit-user/${user.id}`)}
								/>
							</Grid>
						))}
					<Grid item xs={12} md={12}>
						<TablePagination
							component="div"
							count={homeStore.pageCount}
							page={homeStore.page}
							onChangePage={handleChangePage}
							rowsPerPage={homeStore.rowsPerPage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							labelRowsPerPage={''}
						/>
					</Grid>
				</Grid>
			</Container>
		</>
	)
})

export default Home
