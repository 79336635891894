import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../../store/rootStore'
import { useSnackbar } from 'notistack'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	Grid,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Typography
} from '@material-ui/core'

const getInitialValue = data => {
	if (data?.grantsState && data?.roleKey) {
		const selectState = data.grantsState?.reduce((acc, item) => {
			acc[item.key] = item.values
			return acc
		}, {})
		return {
			roleId: data.roleId,
			roleKey: data.roleKey,
			...selectState
		}
	}
	return { roleId: '', roleKey: '' }
}

const AddSuperset = observer(({ open, onClose = () => {}, data = {} }) => {
	const { profileStore } = useContext(RootStoreContext)
	const { enqueueSnackbar } = useSnackbar()
	const isEdit = !!data?.roleId
	const [sendData, setSendData] = useState(getInitialValue(data))
	const [roleInfo, setRoleInfo] = useState([])
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		if (!!sendData?.roleId) {
			setLoading(true)
			profileStore.getSupersetRoleInfo(sendData.roleId).then(res => {
				if (setErrOnRes(res)) {
					setRoleInfo(res)
					console.log(res)
				}
				setLoading(false)
			})
		}
		return () => {}
	}, [sendData.roleId])

	const setErrOnRes = res => {
		if (res && res.hasOwnProperty('errStatus')) {
			loading && setLoading(false)
			if (res.errStatus === 401) {
				return false
			} else {
				enqueueSnackbar(res.msg, { variant: 'error' })
				return false
			}
		}
		return true
	}
	const onChangeRole = e => {
		const { value } = e.target

		const roleKey = profileStore.superset.rolesList.find(
			item => item.id?.toString() === value
		)?.code
		setSendData(prev => ({ ...prev, roleId: value, roleKey }))
	}

	const onChangeSelect = e => {
		const { name, value } = e.target
		setSendData(prev => ({ ...prev, [name]: value }))
	}

	const onSave = () => {
		const { roleId, roleKey, ...rest } = sendData
		const grantsState = Object.keys(rest).map(key => ({
			key,
			values: rest[key]
		}))
		const data = {
			roleId,
			roleKey,
			grantsState
		}
		setLoading(true)
		profileStore.saveSupersetRole(data).then(res => {
			if (setErrOnRes(res)) {
				/*profileStore.getSupersetAccesses().then(res => {
					onClose()
				})*/
			}
		})
	}

	return (
		<Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
			<DialogTitle>
				{isEdit
					? `Редактировать доступ - ${data?.roleTitle}`
					: 'Добавить доступ Superset'}
			</DialogTitle>
			<DialogContent dividers>
				{loading ? (
					<div
						style={{
							height: 200,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<CircularProgress />
						<Typography variant={'h6'}>Загрузка...</Typography>
					</div>
				) : (
					<Grid container spacing={2}>
						{!isEdit && (
							<Grid item xs={12} md={12}>
								<FormControl fullWidth>
									<InputLabel id="roleId-label">Роль</InputLabel>
									<Select
										labelId="roleId-label"
										id="roleId-label"
										value={sendData.roleId}
										onChange={onChangeRole}
										fullWidth
									>
										{profileStore.superset?.rolesList?.map(role => (
											<MenuItem key={role?.code} value={role?.id}>
												{role?.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						)}
						{roleInfo?.length === 0 ? (
							<Grid item xs={12} md={12}>
								<Typography variant={'h3'} color={'secondary'}>
									Выберите роль!
								</Typography>{' '}
							</Grid>
						) : (
							roleInfo?.map(item => (
								<Grid item xs={12} md={12} key={`select_${item?.grantId}`}>
									<FormControl fullWidth>
										<InputLabel id={`select_${item?.key}-label`}>
											{item?.title}
										</InputLabel>
										<Select
											labelId={`select_${item?.key}-label`}
											name={item?.key}
											value={sendData?.[item?.key] || []}
											onChange={onChangeSelect}
											multiple
											input={<Input />}
											fullWidth
											renderValue={selected =>
												item?.values
													?.filter(value => selected.indexOf(value?.id) !== -1)
													?.map(value => value?.title)
													?.join(', ') || ''
											}
										>
											{item?.values?.map(value => (
												<MenuItem
													key={`select_${item?.key}_${value?.id}`}
													value={value?.id}
												>
													<Checkbox
														checked={
															sendData[item?.key]?.indexOf(value?.id) > -1
														}
													/>
													<ListItemText primary={value?.title} />
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							))
						)}
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button color={'secondary'} onClick={onClose}>
					Закрыть
				</Button>
				<Button color={'primary'} onClick={onSave}>
					Сохранить
				</Button>
			</DialogActions>
		</Dialog>
	)
})
export default AddSuperset
