import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { RootStoreContext } from '../../../store/rootStore'
import AutoCompleteFieldWithTwoLabels from '../../AutoCompleteFieldWithTwoLabels'

const CopyAccess = observer(() => {
	const { profileStore } = useContext(RootStoreContext)
	const { t } = useTranslation()
	const md = 6
	const fullW = 12

	const handleChangeSelect = (name, value) => {
		profileStore.setUserIdToCopy(value)
	}

	return (
		<Grid
			container
			direction={'row'}
			justify={'center'}
			alignItems={'center'}
			spacing={2}
		>
			<Grid item xs={fullW} md={md}>
				<AutoCompleteFieldWithTwoLabels
					arrData={profileStore.userList}
					keyArrOne={'firstName'}
					keyArrTwo={'lastName'}
					value={profileStore.userIdToCopy}
					setValue={handleChangeSelect}
					name={'userIdToCopy'}
					id="userIdToCopy"
					label={t('profile.inputName')}
				/>
			</Grid>
		</Grid>
	)
})
export default CopyAccess
