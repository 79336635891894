import React from 'react'

import {
	Button,
	Chip,
	Grid,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles(theme => ({
	rootPaper: {
		padding: theme.spacing(2)
	},
	chip: {
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(1),
		color: 'white',
		backgroundColor: '#0664a5'
	}
}))
const CardReports = ({ reportList, reportCodes, label, onClickEdit }) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const getLabel = value => {
		const label = reportList
			? reportList.find(arrItem => arrItem.code === value)
			: { name: value }
		return label && label.name
	}
	return (
		<Paper elevation={3} className={classes.rootPaper} key={'paper_reports'}>
			<Grid
				container
				direction={'row'}
				justify={'flex-start'}
				alignItems={'center'}
				spacing={2}
			>
				<Grid item xs={12} md={12}>
					<Typography variant={'h6'}>{label}</Typography>
				</Grid>
				<Grid item xs={12} md={12}>
					{reportCodes &&
						reportCodes.length !== 0 &&
						reportCodes.map((value, index) => (
							<Chip
								className={classes.chip}
								label={getLabel(value)}
								key={`chip_reports_${index}`}
							/>
						))}
					{reportCodes && reportCodes.length === 0 && (
						<Typography variant={'body2'}>
							{t('profile.emptyReports')}
						</Typography>
					)}
				</Grid>
				<Grid
					item
					container
					direction={'row'}
					xs={12}
					md={12}
					justify={'flex-start'}
					alignItems={'center'}
					spacing={2}
				>
					<Grid item>
						<Button
							variant={'contained'}
							color={'primary'}
							onClick={onClickEdit}
						>
							{reportCodes && reportCodes.length === 0
								? t('profile.addReports')
								: t('profile.edit')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	)
}
export default CardReports
