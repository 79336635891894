import React from 'react'
import {
	Button,
	Chip,
	Grid,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
	rootPaper: {
		padding: theme.spacing(2)
	},
	chip: {
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(1),
		color: 'white',
		backgroundColor: '#0664a5'
	}
}))
const CardAccess = ({ role, branches, deleteRole, onClickEdit }) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const findNameBranch = code => {
		if (branches) {
			const branch = branches.find(val => val.code === code)
			return branch && branch.hasOwnProperty('name') && branch.name
		}
	}
	return (
		<Paper elevation={3} className={classes.rootPaper} key={'paper_access'}>
			<Grid
				container
				direction={'row'}
				justify={'flex-start'}
				alignItems={'center'}
				spacing={2}
			>
				<Grid item xs={12} md={12}>
					<Typography variant={'h6'}>{role.project.name}</Typography>
				</Grid>
				<Grid item xs={12} md={12}>
					<Typography variant={'body2'}>{role.name}</Typography>
				</Grid>
				<Grid item xs={12} md={12}>
					{role.branchList !== null &&
						role.branchList !== undefined &&
						role.branchList.map((branch, index) => (
							<Chip
								className={classes.chip}
								key={`code_${index}_${branch}`}
								label={findNameBranch(branch)}
							/>
						))}
				</Grid>
				<Grid
					item
					container
					direction={'row'}
					xs={12}
					md={12}
					justify={'flex-start'}
					alignItems={'center'}
					spacing={2}
				>
					<Grid item>
						<Button
							variant={'contained'}
							color={'secondary'}
							onClick={deleteRole}
						>
							{t('profile.delete')}
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant={'contained'}
							color={'primary'}
							onClick={onClickEdit}
						>
							{t('profile.edit')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	)
}
export default CardAccess
