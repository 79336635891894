import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {Checkbox, Grid, FormControlLabel} from '@material-ui/core'
import AutoCompleteField from '../../AutoCompleteField'
import { useTranslation } from 'react-i18next'
import { RootStoreContext } from '../../../store/rootStore'
import { useHistory } from 'react-router'
import { useSnackbar } from 'notistack'
import AutoCompleteFieldCheckbox from '../../AutoCompleteFieldCheckbox'
import { toJS } from 'mobx'

const AddAccess = observer(({ isEditRole }) => {
	const { homeStore, profileStore } = useContext(RootStoreContext);
	const history = useHistory()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation()
	const md = 12
	const fullW = 12
	const setErrOnRes = res => {
		if (res && res.hasOwnProperty('errStatus')) {
			if (res.errStatus === 401) {
				history.push('/signin')
				return false
			} else {
				enqueueSnackbar(res.msg, { variant: 'error' })
				return false
			}
		}
		return
	}
	useEffect(() => {
		if (profileStore.newRole.selectedProject) {
			homeStore
				.getRoles(profileStore.newRole.selectedProject.code)
				.then(res => {
					setErrOnRes(res)

					if (isEditRole) {
						const selectedRole = homeStore.roles.find(
							value => value.code === profileStore.newRole.selectedRole.code,

						)
						handleChangeSelect('selectedRole', selectedRole)
					}
				})
		}
	}, [profileStore.newRole.selectedProject, isEditRole])

	const handleChangeSelect = (name, value) => {
		profileStore.setNewRoleValue(name, value)
	}
	const handleAllBranches = () =>
		profileStore.newRole.selectedBranches !== homeStore.branches ?
			profileStore.setNewRoleValue('selectedBranches', homeStore.branches) :
			profileStore.setNewRoleValue('selectedBranches', [])
	const haveRoles = profileStore.user.roles.length > 0
	const projectsArr = isEditRole
		? homeStore.projects
		: haveRoles
		? homeStore.projects.filter(
				value =>
					!profileStore.user.roles
						.map(itemRole => itemRole.project.code === value.code)
						.includes(true)
		  )
		: homeStore.projects
		
	return (
		<Grid
			container
			direction={'row'}
			justify={'flex-start'}
			alignItems={'center'}
			spacing={2}
		>
			<Grid item xs={fullW} md={md}>
				<AutoCompleteField
					arrData={projectsArr}
					keyArr={'name'}
					value={profileStore.newRole.selectedProject}
					setValue={handleChangeSelect}
					name={'selectedProject'}
					id="selectedProject"
					label={t('profile.module')}
				/>
			</Grid>
			<Grid item xs={fullW} md={md}>
				<AutoCompleteField
					arrData={homeStore.roles}
					value={profileStore.newRole.selectedRole}
					disabled={!profileStore.newRole.selectedProject}
					setValue={handleChangeSelect}
					keyArr={'name'}
					name={'selectedRole'}
					id="role"
					label={t('profile.role')}
				/>
			</Grid>
			<Grid item xs={fullW} md={md}>
				<AutoCompleteFieldCheckbox
					arrData={toJS(homeStore.branches)}
					value={profileStore.newRole.selectedBranches}
					setValue={handleChangeSelect}
					keyArr={'name'}
					name={'selectedBranches'}
					id="selectedBranches"
					label={t('profile.branches')}
				/>
			</Grid>
			<Grid item xs={fullW} md={md}>
				<FormControlLabel
					control={
						<Checkbox
							checked={profileStore.newRole.selectedBranches === homeStore.branches}
							onChange={handleAllBranches}
							name="allBranches"
							color="secondary"
						/>
					}
					label={t('profile.all')}
				/>
			</Grid>
		</Grid>
	)
})
export default AddAccess
