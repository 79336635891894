import { useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import axios from 'axios'
import { AUTH_API_URL } from '../../lib/env'
import { filterInit } from '../../constants/initialStates'

const initialValues = {
	loading: false,
	users: [],
	pageCount: 0,
	page: 0,
	rowsPerPage: 10,
	branches: [],
	projects: [],
	subdivisions: [],
	roles: [],
	filter: filterInit
}

const HomeContext = () => {
	const store = useLocalObservable(() => ({
		/*observables*/
		...initialValues,
		setLoading() {
			runInAction(() => {
				store.loading = !store.loading
			})
		},
		setPages(page, rowsPerPage) {
			runInAction(() => {
				store.page = page
				store.rowsPerPage = rowsPerPage
			})
		},
		setFilter(name, value) {
			runInAction(() => {
				store.filter = { ...store.filter, [name]: value }
			})
		},
		clearFilter() {
			runInAction(() => {
				store.filter = filterInit
			})
		},
		async getUsers() {
			const filterVal = store.filter
			const filter = {
				username:
					filterVal.username.length > 0 ? filterVal.username : undefined,
				userFullname:
					filterVal.userFullname.length > 0
						? filterVal.userFullname
						: undefined,
				roleCode: filterVal.roleCode ? filterVal.roleCode.code : undefined,
				subdivision: filterVal.subdivision
					? filterVal.subdivision.name
					: undefined,
				projectCode: filterVal.projectCode
					? filterVal.projectCode.code
					: undefined,
				branchCode: filterVal.branchCode
					? filterVal.branchCode.code
					: undefined,
				isActive: filterVal.isActive !== 'all' ? filterVal.isActive : undefined,
				isSystemUser: filterVal.isSystemUser !== null
					? filterVal.isSystemUser
					: undefined,
			}
			this.setLoading()
			try {
				const res = await axios.post(AUTH_API_URL + '/users/getUsers', {
					offset: store.page * store.rowsPerPage,
					limit: store.rowsPerPage,
					...filter
				})
				await runInAction(() => {
					store.users = res.data.userList
					store.pageCount = res.data.countFiltered
				})
				this.setLoading()
				return res.data.countFiltered
			} catch (error) {
				this.setLoading()
				if (error.response.status === 401) {
					return { errStatus: 401, msg: 'Ошибка автаризации' }
				}
				return {
					errStatus: error.response.status,
					msg: error.response.data.message
				}
			}
		},
		async getBranches() {
			this.setLoading()
			try {
				const res = await axios.get(AUTH_API_URL + '/branches')
				await runInAction(() => {
					store.branches = res.data
				})
				this.setLoading()
				return
			} catch (error) {
				this.setLoading()
				if (error.response.status === 401) {
					return { errStatus: 401, msg: 'Ошибка автаризации' }
				}
				return {
					errStatus: error.response.status,
					msg: error.response.data.message
				}
			}
		},
		async getProjects() {
			this.setLoading()
			try {
				const res = await axios.get(AUTH_API_URL + '/projects/getActive')
				await runInAction(() => {
					store.projects = res.data
				})
				this.setLoading()
				return
			} catch (error) {
				this.setLoading()
				if (error.response.status === 401) {
					return { errStatus: 401, msg: 'Ошибка автаризации' }
				}
				return {
					errStatus: error.response.status,
					msg: error.response.data.message
				}
			}
		},
		async getRoles(selectedProject) {
			this.setLoading()
			try {
				const res = await axios.get(AUTH_API_URL + '/roles/' + selectedProject)
				await runInAction(() => {
					store.roles = res.data
				})
				this.setLoading()
				return
			} catch (error) {
				this.setLoading()
				if (error.response.status === 401) {
					return { errStatus: 401, msg: 'Ошибка автаризации' }
				}
				return {
					errStatus: error.response.status,
					msg: error.response.data.message
				}
			}
		},
		async getSubdivisions() {
			this.setLoading()
			try {
				const res = await axios.post(
					AUTH_API_URL + '/dictionary/subdivisions',
					{
						active: true,
					}
				)
				await runInAction(() => {
					store.subdivisions = res.data
				})
				this.setLoading()
				return
			} catch (error) {
				this.setLoading()
				if (error.response.status === 401) {
					return { errStatus: 401, msg: 'Ошибка автаризации' }
				}
				return {
					errStatus: error.response.status,
					msg: error.response.data.message
				}
			}
		}
	}))

	return store
}

export default HomeContext
