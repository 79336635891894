import React, { useContext, useEffect, useRef, useState } from 'react'
import '../styles/sigIn.css'
import { useTranslation } from 'react-i18next'
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	TextField,
	Typography
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../store/rootStore'
import { useSnackbar } from 'notistack'

const SignIn = observer(() => {
	const { authStore } = useContext(RootStoreContext)
	const { enqueueSnackbar } = useSnackbar()
	const _isMounted = useRef(true)
	const { t } = useTranslation()
	let history = useHistory()
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	useEffect(() => {
		authStore.clearStorage()
		return () => {
			_isMounted.current = false
		}
	}, [])

	const checkRole = data =>
		data &&
		data.hasOwnProperty('roleList') &&
		data.roleList
			.map(
				value =>
					value.hasOwnProperty('project') &&
					value.project.code === 'UA' &&
					value.code === 'ADMIN'
			)
			.includes(true)

	const handleUsername = e => {
		setUsername(e.target.value)
	}

	const handlePassword = e => {
		setPassword(e.target.value)
	}

	useEffect(() => {
		if (checkRole(authStore.user.data)) {
			history.push('/home')
		}
	}, [authStore.user.data])

	const login = () => {
		const data = {
			username: username,
			password: password
		}
		authStore.login(data).then(res => {
			if (res.hasOwnProperty('errStatus')) {
				enqueueSnackbar(res.msg, {
					variant: 'error'
				})
				return
			}
			if (!checkRole(res)) {
				enqueueSnackbar(t('notAccess'), {
					variant: 'error'
				})
				return
			}
		})
	}

	return (
		<div style={{ textAlign: 'center' }}>
			{authStore.loading && (
				<CircularProgress
					style={{
						position: 'absolute',
						zIndex: 9,
						top: '50%',
						left: '48%'
					}}
					color="secondary"
				/>
			)}
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justify="center"
				style={{ minHeight: '70vh' }}
			>
				<Grid item xs={12}>
					<form>
						<Paper
							style={{
								padding: '60px',
								paddingBottom: '60px',
								minWidth: '250px',
								maxWidth: '300px'
							}}
						>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="h5" component="h3">
										{t('Authorization')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<TextField
										style={{ width: '100%' }}
										id="username"
										autoComplete="username"
										value={username}
										label={t('username')}
										type="text"
										margin="normal"
										onChange={handleUsername}
										required
									/>
								</Grid>
								<Grid item xs={12} style={{ paddingBottom: '20px' }}>
									<TextField
										style={{ width: '100%' }}
										value={password}
										id="password"
										autoComplete="current-password"
										label={t('password')}
										type="password"
										onChange={handlePassword}
										margin="normal"
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										className="button"
										onClick={login}
										autoFocus
										style={{
											marginTop: '30px',
											width: '100%'
										}}
									>
										{t('sigIn')}
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</form>
				</Grid>
			</Grid>
		</div>
	)
})

export default SignIn
